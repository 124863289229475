import React, { useState, useEffect, useContext, useRef } from 'react'
import { ProductContext } from '../../../../sea-site-theme/src/context/addProductContext'
import useWindowResize from '../../../../sea-site-theme/src/hooks/useWindowResize'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { Client } from '../../../../sea-site-theme/src/apollo/client'
import isEqual from 'lodash.isequal'
import uniq from 'lodash.uniq'
import uniqWith from 'lodash.uniqwith'
import { graphql, useStaticQuery } from 'gatsby'
import { HelpCircle, Circle } from 'react-feather'
import '@reach/accordion/styles.css'
import '@reach/tooltip/styles.css'
import { ChevronUp } from 'react-feather'
import Dolphin from '../../../../sea-site-theme/src/svg/dolphin'
import Mline from '../../../../sea-site-theme/src/svg/m-line'
import Sline from '../../../../sea-site-theme/src/svg/s-line'
import Xline from '../../../../sea-site-theme/src/svg/x-line'
import WaveLine from '../../../../sea-site-theme/src/svg/wave'
// import Swash from '../svg/swash-line'
// import Liberty from '../svg/liberty-line'
import {
	DetailsSection,
	DetailsContainer,
	ProductContainer,
	ProductTitle,
	ProductsName,
	RemoveSvg,
	ImageRow,
	BrandImage,
	ImageContainer,
	ProductImage,
	SkuContainer,
	SkuTitle,
	Sku,
	LineContainer,
	LineTitle,
	ProductLineContainer,
	RrpContainer,
	RrpTitle,
	Rrp,
	FeatureRow,
	Acc,
	AccItem,
	AccButton,
	AccPanel,
	AccordionButtonHolder,
	CheckMark,
	CustomizedToolTip,
	AddToCartContainer,
	AddToCartTitle,
	AddBook,
	Request,
	Book,
} from './styles'
import tw from 'twin.macro'

interface TabDetails {
	addDetails: string[]
	tabDetails: string[]
	activeSlug: string
}

interface FeatureDetails {
	// featuredetails: {
	// items: {
	featuresCollection: {
		items: {
			title: string
			description: string
		}[]
		seoTitle: string
	}
	// }[]
	// }
}

interface Specification {
	// specificationdetails?: {
	// items: {
	seoTitle: string
	specifications: {
		spec: string
		details: string
	}[]
	// }[]
	// }
}

interface ProductDatas {
	// maindetails?:
	// 	| {
	// items: {
	active: boolean
	brand: string
	brandPage: {
		media: {
			description: string
			url: string
		}
	}
	categories: {
		title: string
	}
	imagesCollection: {
		url: string
	}[]
	model: string
	price: number
	seoTitle: string
	series: string
	seriesPage: {
		media: null | string
	}
	sku: string
	slug: string
	// }[]
	//   }
	// | undefined
}

type Img = {
	node: {
		images: {
			description: string
			fluid: {
				src: string
				srcWebp: string
			}
		}[]
		node_locale: string
		slug: string
	}
}
interface ProductImage {
	productImage: {
		edges: Img[]
	}
}

const APOLLO_TAB_DETAILS_QUERY = gql`
	query tabDetailsQuery($title: [String]!) {
		maindetails: productCollection(where: { seoTitle_in: $title }, locale: "th-TH") {
			items {
				seoTitle
				model
				brand
				sku
				series
				price
				slug
				active
				categories {
					title: seoTitle
				}
				imagesCollection {
					items {
						url
					}
				}
				brandPage {
					media {
						url
						description
					}
				}
				seriesPage {
					media {
						url
						description
					}
				}
			}
		}
	}
`
const APOLLO_SPEC_QUERY = gql`
	query specDetails($title: [String]!) {
		specificationdetails: productCollection(where: { seoTitle_in: $title }, locale: "th-TH") {
			items {
				seoTitle
				specifications
			}
		}
	}
`
const APOLLO_FEATURE_QUERY = gql`
	query featureDetails($title: [String]!) {
		featuredetails: productCollection(where: { seoTitle_in: $title }, locale: "th-TH") {
			items {
				seoTitle
				featuresCollection {
					items {
						title
						description
					}
				}
			}
		}
	}
`

const TabDetails = ({ addDetails, tabDetails, activeSlug }: TabDetails): JSX.Element => {
	const [table] = useContext(ProductContext)
	const [dataPlot, setDataPlot] = useState<string[]>([])
	const [productDatas, setProductDatas] = useState<ProductDatas[]>([])
	const [specificationsData, setSpecificationsData] = useState<Specification[]>([])
	const [featureDetails, setFeatureDetails] = useState<FeatureDetails[]>([])
	const [isOpen, toggle] = useState(false)
	const [isFeatureOpen, featureToggle] = useState(false)
	const { width } = useWindowResize()
	const clickHandler = () => {
		toggle(!isOpen)
	}
	const featureClickHandler = () => {
		featureToggle(!isFeatureOpen)
	}

	useEffect(() => {
		const tabDtl = window.sessionStorage.getItem(activeSlug)

		if (tabDtl != null) {
			if (width < 1023) {
				const alteredDataPlot = [...dataPlot]
				if (alteredDataPlot.length > 2) {
					alteredDataPlot.splice(alteredDataPlot.length - 1, alteredDataPlot.length - 1)
					setDataPlot(alteredDataPlot)
				}
			} else {
				setDataPlot(JSON.parse(tabDtl))
			}
		}
	}, [width])

	useEffect(() => {
		const tabDtl = window.sessionStorage.getItem(activeSlug)
		if (table.length === 0 && addDetails.length === 0 && tabDetails.length === 0) {
			if (tabDtl != null) {
				setDataPlot(JSON.parse(tabDtl))
				return
			}
		}
		if (tabDetails.length > 0 || tabDetails.length === 0) {
			setDataPlot(tabDetails)
		}
	}, [table, activeSlug])

	useEffect(() => {
		if (addDetails.length > 0) {
			setDataPlot(addDetails)
		}
	}, [table])

	const { data } = useQuery(APOLLO_TAB_DETAILS_QUERY, {
		variables: { title: dataPlot },
		client: Client,
	})
	// console.log(data)
	const { data: specData } = useQuery(APOLLO_SPEC_QUERY, {
		variables: { title: dataPlot },
		client: Client,
	})
	const { data: featureData } = useQuery(APOLLO_FEATURE_QUERY, {
		variables: { title: dataPlot },
		client: Client,
	})
	useEffect(() => {
		if (data != undefined) {
			const xx = [...data.maindetails.items]
			const sortedDatas = xx.sort((a, b) => {
				return dataPlot.indexOf(a.seoTitle) - dataPlot.indexOf(b.seoTitle)
			})
			xx.splice(0, xx.length, ...sortedDatas)
			let newdata = [...data.maindetails.items]
			newdata = sortedDatas
			setProductDatas(newdata)
		}
	}, [data])
	useEffect(() => {
		if (specData != undefined) {
			const xx = [...specData.specificationdetails.items]
			const sortedDatas = xx.sort((a, b) => {
				return dataPlot.indexOf(a.seoTitle) - dataPlot.indexOf(b.seoTitle)
			})
			xx.splice(0, xx.length, ...sortedDatas)
			let newdata = [...specData.specificationdetails.items]
			newdata = sortedDatas
			setSpecificationsData(newdata)
		}
	}, [specData])
	useEffect(() => {
		if (featureData != undefined) {
			const xx = [...featureData.featuredetails.items]
			const sortedDatas = xx.sort((a, b) => {
				return dataPlot.indexOf(a.seoTitle) - dataPlot.indexOf(b.seoTitle)
			})
			xx.splice(0, xx.length, ...sortedDatas)
			let newdata = [...featureData.featuredetails.items]
			newdata = sortedDatas
			setFeatureDetails(newdata)
		}
	}, [featureData])
	const feature =
		featureDetails != undefined
			? featureDetails
					.map((x) => {
						return x.featuresCollection
					})
					.map((x) => {
						return x.items.map((y) => {
							return y.title
						})
					})
					.sort((a, b) => {
						return b.length - a.length
					})
					.reduce((accumulator, current) => {
						return accumulator.concat(current)
					}, [])
			: []
	const uniqueFeatureKeys = uniq([...feature]) as string[]
	const alteredFeatureKeys = uniqueFeatureKeys.map((x: string) => {
		return {
			featurekeys: x,
			arraydata: [] as string[],
			tooltip: [] as string[],
		}
	})
	featureDetails != undefined
		? featureDetails.map((x) => {
				const title: string[] = []
				const description: [{ [field: string]: string }] | [] = [{}]
				x.featuresCollection.items.map((a) => {
					title.push(a.title)
					description.push(a)
				})
				const arr: string[] = []
				uniqueFeatureKeys.map((x) => {
					const xx = title.includes(x)
					if (xx) {
						description.map((a) => {
							if (x === a.title) {
								arr.push('check')
							}
							1
						})
					} else {
						arr.push('')
					}
				})
				arr.map((a: string, b) => {
					alteredFeatureKeys[b].arraydata.push(a)
				})
		  })
		: []

	const featureToolTip =
		featureDetails != undefined
			? featureDetails
					.map((x) => {
						return x.featuresCollection
					})
					.map((x) => {
						return x.items.map((y) => {
							return y
						})
					})
					.sort((a, b) => {
						return b.length - a.length
					})
					.reduce((accumulator, current) => {
						return accumulator.concat(current)
					}, [])
			: []
	const uniqueTooltip = uniqWith(featureToolTip, isEqual)

	uniqueTooltip.map((x: { description: string }, y: number) => {
		alteredFeatureKeys[y].tooltip.push(x.description)
	})

	const spec =
		specificationsData != undefined
			? specificationsData != undefined
				? specificationsData
						.map((x) => {
							return x.specifications
						})
						.map((x) => {
							return x.map((y) => {
								return y.spec
							})
						})
						.sort((a, b) => {
							return b.length - a.length
						})
						.reduce((accumulator, current) => {
							return accumulator.concat(current)
						}, [])
				: []
			: []

	const uniqueKeys = uniq([...spec])
	const alteredKeys = uniqueKeys.map((x: string) => {
		return {
			featurekeys: x as string,
			arraydata: [] as string[],
		}
	})
	specificationsData != undefined
		? specificationsData.map((x) => {
				const specs: string[] = []
				const details: [{ [field: string]: string }] = [{}]
				x.specifications.map((a) => {
					specs.push(a.spec)
					details.push(a)
				})
				const arr: string[] = []
				uniqueKeys.map((x: string) => {
					const xx = specs.includes(x)
					if (xx) {
						details.map((a) => {
							if (x === a.spec) {
								arr.push(a.detail ? a.detail : a.details)
							}
						})
					} else {
						arr.push('')
					}
				})
				arr.map((a, b) => {
					alteredKeys[b].arraydata.push(a)
				})
		  })
		: []

	const removeProductHandler = (name: string, index: number) => {
		const fetchSession = sessionStorage.getItem(activeSlug)
		const parseSession = fetchSession != null ? JSON.parse(fetchSession) : []
		const filteredSession = parseSession.filter((item: string) => {
			return item != name
		})
		sessionStorage.setItem(activeSlug, JSON.stringify(filteredSession))
		const stateData = [...productDatas]
		const specsData = [...specificationsData]
		const featureDits = [...featureDetails]
		stateData.splice(index, 1)
		specsData.splice(index, 1)
		featureDits.splice(index, 1)
		setProductDatas(stateData)
		setSpecificationsData(specsData)
		setFeatureDetails(featureDits)
	}

	const { productImage } = useStaticQuery<ProductImage>(graphql`
		query allProduct {
			productImage: allContentfulProduct(filter: { node_locale: { eq: "th-TH" } }) {
				edges {
					node {
						node_locale
						slug
						images {
							fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
								src
								srcWebp
							}
							description
						}
					}
				}
			}
		}
	`)

	const dataCheck = () => {
		if (productDatas !== undefined) {
			return productDatas ? (productDatas.length > 0 ? true : false) : []
		}
	}
	const check = dataCheck()

	const [isSticky, setSticky] = useState<number | undefined>(0)
	const [place, setPlace] = useState<number | undefined>(0)
	const [initialOffset, setInitialOffset] = useState<number | undefined>(0)
	const [ttlHeight, setTtlHeight] = useState<number | undefined>(0)
	const [btnOffset, setBtnOffset] = useState<number | undefined>(0)
	const ref = useRef<HTMLDivElement | undefined>(null)
	const btnRef = useRef<HTMLDivElement | undefined>(null)
	const ttlRef = useRef<HTMLDivElement | undefined>(null)
	const handleScroll = () => {
		ref != null ? setPlace(ref?.current?.getBoundingClientRect()?.top) : ''
		ref != null ? setSticky(ref?.current?.getBoundingClientRect()?.top) : ''
		ttlRef != null ? setTtlHeight(ttlRef?.current?.offsetHeight) : ''
		btnRef != null ? setBtnOffset(btnRef?.current?.offsetTop) : ''
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', () => handleScroll)
		}
	}, [])
	useEffect(() => {
		ref != null ? setInitialOffset(ref?.current?.getBoundingClientRect()?.top) : ''
		btnRef != null ? setBtnOffset(btnRef?.current?.offsetTop) : ''
	}, [ref, btnRef])

	const totalHeight = Math.abs(place as number) + (ttlHeight as number) + (initialOffset as number)
	return (
		<DetailsSection>
			<DetailsContainer ref={ref as React.LegacyRef<HTMLDivElement> | undefined}>
				<div>
					<ProductContainer
						ref={ttlRef as React.LegacyRef<HTMLDivElement> | undefined}
						containerStyle={
							(isSticky as number) < 0
								? totalHeight >= (btnOffset as number)
									? tw`absolute bottom-0 left-0 right-0 z-10 top-auto`
									: tw`fixed pr-16 w-11/12 z-10 top-0`
								: {}
						}
					>
						{check === true ? (
							<>
								<ProductTitle>Product</ProductTitle>
								{productDatas?.map((item, i) => {
									const splitString = item.seoTitle.split(item.brand)
									return (
										<ProductsName key={i}>
											<RemoveSvg width="25" height="25" onClick={() => removeProductHandler(item.seoTitle, i)} />
											{item.brand}
											<br />
											<span>{splitString[1]}</span>
										</ProductsName>
									)
								})}
							</>
						) : (
							''
						)}
					</ProductContainer>
					<ImageRow>
						{check === true ? (
							<>
								<BrandImage>
									{activeSlug === 'residential' || activeSlug === 'commercial' ? (
										<Dolphin width="100%" height="100" />
									) : (
										''
									)}
								</BrandImage>
								{productDatas?.map((item, i) => {
									const filterImg = (img: Img[]) => {
										return img.filter((x) => {
											return x.node.slug === item.slug ? x : ''
										})
									}
									const filteredByslug = filterImg(productImage.edges)
									return (
										<ImageContainer key={i}>
											<ProductImage>
												<source srcSet={filteredByslug[0].node.images[0].fluid.srcWebp} type="image/webp" />
												<source
													srcSet={`${
														filteredByslug[0].node.images[0].fluid.src.split('?')[0]
													}?fm=png&fl=png8&fit=pad&w=600&h=400`}
												/>
												<img
													src={filteredByslug[0].node.images[0].fluid.src}
													alt={filteredByslug[0].node.images[0].description}
												/>
											</ProductImage>
										</ImageContainer>
									)
								})}
							</>
						) : (
							''
						)}
					</ImageRow>
					<SkuContainer>
						{check === true ? (
							<>
								<SkuTitle>SKU</SkuTitle>
								{productDatas?.map((item, i) => {
									return <Sku key={i}>{item.sku}</Sku>
								})}
							</>
						) : (
							''
						)}
					</SkuContainer>
					<LineContainer>
						{check === true ? (
							<>
								<LineTitle>Line</LineTitle>
								{productDatas?.map((item, i) => {
									return (
										<ProductLineContainer key={i}>
											{item.series === 'M Line' ? <Mline width="150" height="80" /> : ''}
											{item.series === 'S Line' ? <Sline width="150" height="80" /> : ''}
											{item.series === 'X Line' ? <Xline width="150" height="80" /> : ''}
											{item.series === 'Wave' ? <WaveLine width="150" height="80" /> : ''}
											{/* {item.series === 'Swash Line' ? <Swash width="150" height="80" /> : ''}
											{item.series === 'Liberty Line' ? <Liberty width="150" height="80" /> : ''} */}
										</ProductLineContainer>
									)
								})}
							</>
						) : (
							''
						)}
					</LineContainer>
					{/* <RrpContainer>
						{check === true ? (
							<>
								<RrpTitle>RRP</RrpTitle>
								{productDatas?.maindetails?.items.map((item, i) => {
									return (
										<Rrp key={i}>
											{item.price.toLocaleString('th-TH', {
												style: 'currency',
												currency: 'SGD',
											})}
										</Rrp>
									)
								})}
							</>
						) : (
							''
						)}
					</RrpContainer> */}
					{check === true ? (
						<Acc collapsible>
							<AccItem>
								<AccordionButtonHolder
									onClick={() => {
										clickHandler()
									}}
								>
									<AccButton>
										{isOpen ? <ChevronUp className="up" /> : <ChevronUp className="down" />}
										Specifications
									</AccButton>
								</AccordionButtonHolder>
								<AccPanel>
									{alteredKeys.map((item: { featurekeys: string; arraydata: string[] }, i: number) => {
										return (
											<FeatureRow key={i}>
												<p>{item.featurekeys}</p>
												{item.arraydata.map((data, i: number) => {
													return (
														<p className={Array.isArray(data) ? 'flexIt' : ''} key={i}>
															{Array.isArray(data)
																? data.map((x, y) => {
																		return (
																			<span key={y}>
																				<Circle width="6" height="6" fill="#000" />
																				{x}
																			</span>
																		)
																  })
																: data}
														</p>
													)
												})}
											</FeatureRow>
										)
									})}
								</AccPanel>
							</AccItem>
						</Acc>
					) : (
						''
					)}
					{check === true ? (
						<Acc collapsible>
							<AccItem>
								<AccordionButtonHolder
									onClick={() => {
										featureClickHandler()
									}}
								>
									<AccButton>
										{isFeatureOpen ? <ChevronUp className="up" /> : <ChevronUp className="down" />}
										Features
									</AccButton>
								</AccordionButtonHolder>
								<AccPanel>
									{alteredFeatureKeys.map((item, i) => {
										return (
											<FeatureRow key={i}>
												<div>
													<span>
														{item.featurekeys}
														<CustomizedToolTip label={item.tooltip}>
															<button>
																<HelpCircle width="22px" height="22px" fill="#58c8df" color="#fff" />
															</button>
														</CustomizedToolTip>
													</span>
												</div>
												{item.arraydata.map((data, i) => {
													return (
														<p key={i}>{data === 'check' ? <CheckMark color="#000" width="30" height="30" /> : ''}</p>
													)
												})}
											</FeatureRow>
										)
									})}
								</AccPanel>
							</AccItem>
						</Acc>
					) : (
						''
					)}
				</div>
				<AddToCartContainer ref={btnRef as React.LegacyRef<HTMLDivElement> | undefined}>
					{check === true ? (
						<>
							<AddToCartTitle></AddToCartTitle>
							{productDatas != undefined
								? productDatas?.map((x, y) => {
										return (
											<AddBook key={y}>
												{x.series != 'Wave' ? (
													x.active === null || x.active === true ? (
														''
													) : (
														<Request
															to={`/contact-us/?subject=${encodeURIComponent(x.seoTitle.toLowerCase())}#contact-form`}
														>
															Enquire Now
														</Request>
													)
												) : (
													<Request
														to={`/contact-us/?subject=commercial+trial+${encodeURIComponent(
															x.model.toLowerCase()
														)}#contact-form`}
													>
														Enquire Now
													</Request>
												)}
												{x.series != 'Wave' && (x.active === null || x.active === true) ? (
													<Request
														to={`/contact-us/?subject=${encodeURIComponent(x.seoTitle.toLowerCase())}#contact-form`}
													>
														Enquire Now
													</Request>
												) : (
													// <Book
													// 	to={`/contact-us/?subject=${encodeURIComponent(x.seoTitle.toLowerCase())}#contact-form`}
													// >
													// 	Enquire Now
													// </Book>
													''
												)}
											</AddBook>
										)
								  })
								: ''}
						</>
					) : (
						''
					)}
				</AddToCartContainer>
			</DetailsContainer>
		</DetailsSection>
	)
}

export default TabDetails
