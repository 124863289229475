import React from 'react'
import { Link } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import Layout from '../../../sea-site-theme/src/components/Layout'
import SEO from '../../../sea-site-theme/src/components/SEO'
import { graphql } from 'gatsby'
import Tabs from '../components/product-comparison/tabs'

export interface ProductImage {
	node: {
		images: {
			fluid: {
				src: string
				srcWebp: string
			}
			description: string
		}[]
		model: string
		node_locale: string
		seoTitle: string
		series: string
		slug: string
	}
}

interface ProductPageProp {
	data: {
		comparisontable: {
			edges: {
				node: {
					node_locale: string
					seoDescription: string
					seoKeywords: string[]
					seoTitle: string
				}
			}[]
		}
		productImage: {
			edges: ProductImage[]
		}
	}
}

const ComparisonSection = styled.section`
	${tw`w-11/12 m-auto pt-16`}
	@media(max-width: 767px) {
		${tw`overflow-x-scroll`}
	}
`
const ComparisonContainer = styled.div`
	@media (max-width: 767px) {
		min-width: 550px;
	}
`
const PageTitle = tw.h1`font-display font-bold text-4xl mb-12`

const ComparisonTable = ({ data: { comparisontable, productImage } }: ProductPageProp): JSX.Element => {
	const locale = 'th-TH'

	const TabsArray = [
		{
			tabName: 'Residential Pool Cleaners',
			brand: 'Dolphin',
			tag: 'residential',
			slug: 'residential',
			active: false,
		},
		{
			tabName: 'Commercial Pool Cleaners',
			brand: 'Dolphin',
			tag: 'commercial',
			slug: 'commercial',
			active: false,
		},
	]

	const [{ node }] = comparisontable.edges
	const filterAllByLocale = (items: ProductImage[], locale: string) => {
		return items.filter((item) => {
			return item.node.node_locale === locale
		})
	}
	const imageNode = filterAllByLocale(productImage.edges, locale)
	const { seoTitle, seoKeywords, seoDescription } = node
	return (
		<Layout>
			<SEO title={seoTitle} keywords={seoKeywords} description={seoDescription} />
			<ComparisonSection>
				<ComparisonContainer>
					<PageTitle>{seoTitle}</PageTitle>
					<p tw="text-lg sm:text-2xl mb-4 sm:mb-6">
						Not sure which product is right for you? Compare up to 3 products. Still not sure?{' '}
						<Link
							tw="underline hover:no-underline"
							to="/support/contact/?subject=which+product+is+right+for+me#contact-form"
						>
							Contact Us
						</Link>
						.
					</p>
					<Tabs title={seoTitle} tabsArray={TabsArray} imageNode={imageNode} />
				</ComparisonContainer>
			</ComparisonSection>
		</Layout>
	)
}

export const comparisonTableQuery = graphql`
	query comparisonTableQuery {
		comparisontable: allContentfulPage(filter: { contentful_id: { eq: "3UbSR2Shf6Q4REP3kx5w2g" } }) {
			edges {
				node {
					node_locale
					seoTitle
					seoKeywords
					seoDescription
				}
			}
		}
		productImage: allContentfulProduct {
			edges {
				node {
					node_locale
					series
					seoTitle
					slug
					model
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
	}
`

export default ComparisonTable
